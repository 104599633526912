import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  dashboardApi,
  fetchConsultationBlocker,
  getNutritionDetails,
  userInfo,
} from '../../services';
import {
  getAllInsuredMembers,
  getAvailableRewards,
  getCompletedRewards,
  getCompletedRewardsAlert,
  getConfig,
  getWellnessPolicies,
} from '../../services/star-health';
import LoadingComponent from '../../components/common/LoadingComponent';

/**
 * Icons goes here
 */
import UserIcon from '../../images/star-health/star_user.png';
import StarUpload from '../../images/star-health/star_upload.png';
import StarRewardIcon from '../../images/common/star-reward-icon.png';
import StarLogo from '../../images/common/star-logo.png';
import SelectPolicyModal from '../../components/star-health/SelectPolicyModal';
import WaterLogCard from '../../components/home/WaterLogCard';
import HomeActionCard from '../../components/home/HomeActionCard';
import hraIcon from '../../images/star-health/wellness-tasks/hra.png';
import labIcon from '../../images/star-health/service-icons/booklab.png';
import offlineIcon from '../../images/home/offline.svg';
import mealIcon from '../../images/star-health/service-icons/meallog.png';
import Tick from '../../images/star-health-status/Tick.svg';
import Cross from '../../images/star-health-status/cross.svg';
import { setPolicyinHomeTab, setWellnessAndHraVisibility } from '../../actions';
import { BoldText, RegularText } from '../../components/common/Text';
import mealLogIcon from '../../images/meal-log/meallogicon.png';
import carbsIcon from '../../images/meal-log/carbs.png';
import proteinsIcon from '../../images/meal-log/proteins.png';
import fatsIcon from '../../images/meal-log/fats.png';
import ProgressBar from '../../components/common/ProgressBar';
import Average5kStepsIcon from '../../images/star-health/wellness-tasks/Average5KSteps.png';
import FitnessDeviceIcon from '../../images/star-health/wellness-tasks/fitnessdevice.png';
import FitnessSuccessStoryIcon from '../../images/star-health/wellness-tasks/FitnessSucessStory.png';
import GymMembershipIcon from '../../images/star-health/wellness-tasks/GymMembership.png';
import HraCompletionIcon from '../../images/star-health/wellness-tasks/hra.png';
import PreventiveRiskAssesmentIcon from '../../images/star-health/wellness-tasks/PreventiveRiskAssesment.png';
import WalkathonIcon from '../../images/star-health/wellness-tasks/Walkathon.png';

const OuterContainer = styled.div`
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  overflow-y: auto;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  border: 1px solid #c8c8c8;
  box-shadow: 2px 2px 0 0 rgba(198, 198, 198, 0.1);
`;

const UserName = styled.div`
  color: #01a39e;
`;

const Card = styled.div`
  border: 1px solid #c8c8c8;
  box-shadow: 2px 2px 0 0 rgba(198, 198, 198, 0.1);
`;

const DashboardHeading = styled.div`
  color: #04427c;
  font-weight: 500;
  font-size: 24px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 14px;
`;

const BodyContainer = styled.div`
  width: 100vw;
  height: 100vh;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

const MainRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 14px;
  padding-right: 14px;
`;

const DisplayCards = styled.div`
  border: 1px solid #c8c8c8;
  box-shadow: 2px 2px 0 0 rgba(198, 198, 198, 0.1);
  border-radius: 8px;
  display: flex;
  height: 45vh;
  flex-direction: column;
`;

const DisplayCardHeading = styled.div`
  width: 100%;
  padding: 14px;
  height: 15%;
  display: flex;
  justify-content: center;
  border-radius: 8px 8px 0px 0px;
  color: #ffffff;
`;

const WellnessCards = styled.div`
  padding: 12px;
  width: 190px;
  height: 160px;
  border: 1px solid #c8c8c8;
  box-shadow: 2px 2px 0 0 rgba(198, 198, 198, 0.1);
  margin: 6px;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  transition: all 0.1s linear;
  border-radius: 8px;
  &:hover {
    box-shadow: 2px 2px 3px 3px rgba(0, 0, 0, 0.2);
  }
`;

const NutritionContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
`;

const TotalCalorieContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TotalCalorieText = styled(BoldText)`
  font-size: 16px;
  color: #2a2a2a;
`;

const RemainingCalorieText = styled(RegularText)`
  font-size: 16px;
  color: #2a2a2a;
`;

const MealLogIcon = styled.img`
  width: 30px;
  height: 38px;
`;

const NutrientIcon = styled.img`
  height: 18px;
  width: 18px;
`;

const NutrientText = styled(BoldText)`
  font-size: 16px;
  color: #2a2a2a;
  margin-top: 16px;
`;

const NutrientLabel = styled(RegularText)`
  font-size: 16px;
  color: #2a2a2a;
  margin-top: 10px;
`;

const NutrientContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const CardHolder = styled.div`
  width: 50%;
  height: 50%;
  transition: all 0.1s linear;
  font-size: 20px;
  &:hover {
    box-shadow: 2px 2px 3px 3px rgba(0, 0, 0, 0.2);
    color: #23527c;
    font-size: 22px;
  }
`;

const CardHolderImage = styled.img`
  width: 64px;
  height: 64px;
  margin-bottom: 12px;
`;

class StarHome extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      userInfo: null,
      totalRewards: 0,
      allPolicies: [],
      selectedPolicy: null,
      membersUnderPolicy: false,
      selectedInsuredMember: null,
      insuredMembers: [],
      selectedPolicyId: null,
      availableWellnessTaskForSelectedPolicy: [],
      completedWellnessTasksForSelectedPolicy: [],
      availableItemsLoading: true,
      completedItemsLoading: true,
      serviceCards: [],
      blockers: {},
      opdPolicyCards: [],
      policyModal: false,
      memberModal: false,
      hraCard: [
        {
          cardType: 'hra-card',
          collapse: false,
          pendingConsult: 0,
          cardDirective: {
            actionType: 'native',
            action: {
              redirectTo: 'hra',
            },
          },
          labelIcon: 'search',
          label: 'Metabolic Profile',
          labelInfo: 'Find a doctor nearby',
          actionIcon: 'arrow',
        },
      ],
      quickActionCards: [
        {
          cardType: 'meal-log',
          collapse: false,
          pendingConsult: 0,
          cardDirective: {
            actionType: 'native',
            action: { redirectTo: 'meal-log' },
          },
          labelIcon: 'search',
          label: 'Log Meal',
          labelInfo: 'Find a doctor nearby',
          actionIcon: 'arrow',
        },
      ],
      mealLog: {
        totalCals: '',
        remainingCals: '',
        totalProgress: '',
        totalCarbs: '',
        carbsGoal: '',
        carbsProgress: '',
        totalProtein: '',
        proteinGoal: '',
        proteinProgress: '',
        totalFats: '',
        fatsGoal: '',
        fatsProgress: '',
      },
    };
  }

  componentDidMount() {
    document.querySelector('#root').style.maxWidth = '100vw';
    this.getConfig();
    this.dashboardApi();
    this.getUserInfo();
    this.getWellnessPolicies();
    this.getCompletedRewardsAlert();

    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const timeStamp = today.valueOf();
    this.getNutritionData(timeStamp);
  }
  componentWillUnmount() {
    document.querySelector('#root').style.maxWidth = '800px';
  }

  dashboardApi() {
    dashboardApi(this.props.authToken)
      .then((res) => {
        console.log(res);
        this.setState({
          serviceCards: res.home[0].cards,
          opdPolicyCards: res.home[1].cards,
        });
      })
      .catch((err) => console.log(err));
  }

  getUserInfo() {
    this.setState({ loading: true });
    userInfo(this.props.authToken)
      .then((res) => {
        console.log(res);
        this.setState({ userInfo: res.userInfo });
      })
      .catch((err) => console.log(err))
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  getWellnessPolicies = () => {
    getWellnessPolicies(this.props.authToken)
      .then((response) => {
        console.log(response);
        let policyId, selectedPolicy;
        if (this.props.selectedPolicyHome) {
          console.log(this.props.selectedPolicyHome);
          selectedPolicy = this.props.selectedPolicyHome;
          policyId = response.result.filter(
            (e) => e.policyNo === this.props.selectedPolicyHome
          )[0].userPolicyId;
          console.log(policyId);
        } else {
          selectedPolicy = response.result[0].policyNo;
          policyId = response.result[0].userPolicyId;
        }
        this.setState({
          allPolicies: response.result,
          selectedPolicy: selectedPolicy,
          selectedPolicyId: policyId,
        });
        this.getInsuredMembersUnderPolicy(policyId);
        // this.setPolicy(response.result[0].policyNo);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  getCompletedRewardsAlert = () => {
    getCompletedRewardsAlert(this.props.authToken)
      .then((res) => {
        console.log(res);
        this.setState({ totalRewards: res.walletBalance });
      })
      .catch((err) => console.log(err));
  };

  hideModal = () => {
    this.setState({
      policyModal: false,
      memberModal: false,
    });
  };

  changePolicy = (e) => {
    this.props.dispatch(setPolicyinHomeTab(e.policyNo));
    this.setState({
      selectedPolicy: e.policyNo,
      selectedPolicyId: e.userPolicyId,
      policyModal: false,
    });
    this.getInsuredMembersUnderPolicy(e.userPolicyId);
  };

  changeMember = (e) => {
    this.setState({ selectedInsuredMember: e, memberModal: false });
    this.getAvailableRewardsByRequestId(
      this.state.selectedPolicyId,
      e.memberId
    );
    this.getCompletedRewardsByRequestId(
      this.state.selectedPolicyId,
      e.memberId
    );
  };

  getAvailableRewardsByRequestId = (policyId, memberId) => {
    getAvailableRewards(this.props.authToken, policyId, memberId)
      .then((res) => {
        console.log(res);
        this.setState({ availableWellnessTaskForSelectedPolicy: res.result });
      })
      .catch((err) => console.log(err))
      .finally(() => {
        this.setState({ availableItemsLoading: false, loading: false });
      });
  };

  getInsuredMembersUnderPolicy = (policyId) => {
    const { authToken } = this.props;
    getAllInsuredMembers(authToken, policyId)
      .then((res) => {
        this.setState({
          membersUnderPolicy: res.data,
          selectedInsuredMember: res.data[0],
        });
        console.log(res.data, 'The member under the policy');
        if (res.data && res.data.length) {
          this.getAvailableRewardsByRequestId(policyId, res.data[0].memberId);
          this.getCompletedRewardsByRequestId(policyId, res.data[0].memberId);
        } else {
          this.getAvailableRewardsByRequestId(policyId, null);
          this.getCompletedRewardsByRequestId(policyId, null);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  getCompletedRewardsByRequestId = (policyId, memberId) => {
    getCompletedRewards(this.props.authToken, policyId, memberId)
      .then((res) => {
        console.log(res);
        this.setState({ completedWellnessTasksForSelectedPolicy: res.result });
      })
      .catch((err) => console.log(err))
      .finally(() => {
        this.setState({ completedItemsLoading: false });
      });
  };

  bookTests = () => {
    const { history } = this.props;
    history.push('/lab-test/select-policy');
  };

  fetchConsultationBlocker = () => {
    fetchConsultationBlocker(this.props.authToken)
      .then((res) => {
        this.setState({
          blockers: res.blockers,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  findSpecialist = (pendingConsult, cancelledRequest) => {
    const { history, authToken, userId } = this.props;

    if (this.state.blockers.cancelledRequest > 0) {
      this.props.history.push('/consultation/cancel-blocker', {
        requestId: this.state.blockers.cancelledRequest,
        goBack: true,
      });
    } else if (this.state.blockers.pendingRequestId > 0) {
      this.props.history.push('/consultation/pending-blocker', {
        requestId: this.state.blockers.pendingRequestId,
        goBack: true,
      });
    } else {
      history.push('/consultation/preview', {
        specialistSearch: false,
        searchType: 'find',
      });
    }
  };

  getNutritionData = (timeStamp) => {
    const { userId, authToken } = this.props;
    this.setState({ loading: true });
    getNutritionDetails(timeStamp, userId, authToken)
      .then((res) => {
        console.log(res);
        this.setState({
          loading: false,
          mealLog: {
            totalCals: res.totalToday,
            remainingCals: res.remainingCals,
            totalProgress: res.userProgress,
            totalCarbs: res.totalMacroNutrients.totalCarbohydrates,
            carbsGoal: res.carbsGoal,
            carbsProgress: res.carbsProgress,
            totalProtein: res.totalMacroNutrients.totalProtein,
            proteinGoal: res.proteinGoal,
            proteinProgress: res.proteinProgress,
            totalFats: res.totalMacroNutrients.totalFat,
            fatsGoal: res.fatsGoal,
            fatsProgress: res.fatsProgress,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  logMealNavigator = () => {
    this.props.history.push('/logmeal', {
      from: 'normal',
    });
  };

  getWellnessTaskIcon = (taskName) => {
    switch (taskName) {
      case 'Participate in Walkathon , Marathon etc':
        return WalkathonIcon;
      case 'Health Club Membership for 1 year':
        return GymMembershipIcon;
      case 'Fitness success story':
        return FitnessSuccessStoryIcon;
      case 'Average 5K Steps over the year':
        return Average5kStepsIcon;
      case 'Complete HRA':
        return HraCompletionIcon;
      case 'Preventive Risk Assessment':
        return PreventiveRiskAssesmentIcon;
      case 'Sync your fitness device':
        return FitnessDeviceIcon;
      default:
        return StarRewardIcon;
    }
  };

  getConfig = () => {
    getConfig(this.props.authToken)
      .then((response) => {
        this.props.dispatch(setWellnessAndHraVisibility(response.rewardActive));
      })
      .catch((err) => console.log(err));
  };

  render() {
    const {
      userInfo,
      loading,
      totalRewards,
      selectedPolicy,
      policyModal,
      allPolicies,
      availableWellnessTaskForSelectedPolicy,
      completedWellnessTasksForSelectedPolicy,
      selectedPolicyId,
      availableItemsLoading,
      completedItemsLoading,
      serviceCards,
      opdPolicyCards,
      hraCard,
      quickActionCards,
      memberModal,
      membersUnderPolicy,
      selectedInsuredMember,
    } = this.state;
    if (loading) {
      return <LoadingComponent />;
    }

    const renderAvaiableWellnessTasks = (tasks) => {
      return tasks.map((each) => {
        const Icon = this.getWellnessTaskIcon(each.rewardName);
        return (
          <WellnessCards
            className="flex flex-col items-center"
            onClick={() => {
              this.props.history.push('/home/wellness-rewards', {
                policyId: selectedPolicyId,
                currentState: 'Available',
                memberId: selectedInsuredMember.memberId,
              });
            }}
          >
            <img
              src={Icon}
              style={{
                width: '44px',
                height: '44px',
                margin: '12px',
              }}
              className="flex flex-row items-center justify-center w-full"
            />
            <p className="text-center">{each.rewardName}</p>
          </WellnessCards>
        );
      });
    };

    const renderCompletedWellnessTasks = (tasks) => {
      return tasks.map((each) => {
        const Icon = this.getWellnessTaskIcon(each.rewardName);
        return (
          <WellnessCards
            className="flex-col relative justify-center"
            onClick={() => {
              this.props.history.push('/home/wellness-reward-status', {
                policyId: selectedPolicyId,
                rewardId: each.rewardReqId,
                authToken: this.props.authToken,
                from: 'star-home',
              });
            }}
          >
            <div className="w-full flex flex-col items-center">
              <img
                src={Icon}
                style={{
                  width: '44px',
                  height: '44px',
                  margin: '12px',
                  marginTop: '-28px',
                }}
                className="flex flex-row items-center justify-center"
              />
              <p className="text-center -mt-6px">{each.rewardName}</p>
            </div>
            <div className="flex flex-row pb-4px items-center pr-4px justify-start absolute bottom-1 right-1">
              {each.rewardStatus != 'rejected' ? (
                <>
                  <img src={Tick} className="mr-4px" width={'18px'} />
                  <div style={{ color: '#3F8CFF', fontWeight: '600' }}>
                    {each.rewardStatus[0].toUpperCase() +
                      each.rewardStatus.slice(1)}
                  </div>
                </>
              ) : (
                <>
                  <img src={Cross} className="mr-4px" width={'18px'} />
                  <div style={{ color: '#FF865C', fontWeight: '600' }}>
                    {each.rewardStatus[0].toUpperCase() +
                      each.rewardStatus.slice(1)}
                  </div>
                </>
              )}
            </div>
          </WellnessCards>
        );
      });
    };

    const mealLogProgress = () => {
      const { mealLog } = this.state;
      return (
        <div>
          <NutritionContainer>
            <TotalCalorieContainer>
              <TotalCalorieText>{`${mealLog.totalCals} of ${
                mealLog.totalCals + mealLog.remainingCals
              } Cals`}</TotalCalorieText>
              <RemainingCalorieText as="span">
                <BoldText as="span">{`${mealLog.remainingCals} Cals`}</BoldText>{' '}
                left for today
              </RemainingCalorieText>
            </TotalCalorieContainer>
            <MealLogIcon src={mealLogIcon} />
          </NutritionContainer>
          <ProgressBar width={`${mealLog.totalProgress * 100}%`} />
          <NutritionContainer>
            <NutrientContainer>
              <NutrientIcon src={carbsIcon} />
              <NutrientText>{`${mealLog.totalCarbs}/${mealLog.carbsGoal}g`}</NutrientText>
              <ProgressBar
                height="3px"
                width={`${mealLog.carbsProgress * 100}%`}
              />
              <NutrientLabel>Carbs</NutrientLabel>
            </NutrientContainer>
            <NutrientContainer>
              <NutrientIcon src={proteinsIcon} />
              <NutrientText>{`${mealLog.totalProtein}/${mealLog.proteinGoal}g`}</NutrientText>
              <ProgressBar
                height="3px"
                width={`${mealLog.proteinProgress * 100}%`}
              />
              <NutrientLabel>Proteins</NutrientLabel>
            </NutrientContainer>
            <NutrientContainer>
              <NutrientIcon src={fatsIcon} />
              <NutrientText>{`${mealLog.totalFats}/${mealLog.fatsGoal}g`}</NutrientText>
              <ProgressBar
                height="3px"
                width={`${mealLog.fatsProgress * 100}%`}
              />
              <NutrientLabel>Fats</NutrientLabel>
            </NutrientContainer>
          </NutritionContainer>
        </div>
      );
    };
    const renderServiceCards = (cards) => {
      return cards.map((ele, idx) => {
        switch (ele.cardType) {
          case 'lab-tests':
            return (
              <CardHolder>
                {/* <HomeActionCard
                  onTap={this.bookTests}
                  title={ele.label}
                  icon={labIcon}
                  offer={ele.offer}
                  discountText={ele.labelBanner}
                  earnBadge={ele.ftLabel && ele.ftLabel.trim() !== ''}
                  className={'h-full flex items-center justify-center'}
                /> */}
                <Card
                  className="h-full p-12px cursor-pointer flex flex-col items-center justify-center"
                  onClick={this.bookTests}
                >
                  <CardHolderImage src={labIcon} />
                  <p>{ele.label}</p>
                </Card>
              </CardHolder>
            );
          // case 'con-search':
          //   return (
          //     <CardHolder>
          //       {/* <HomeActionCard
          //         key={idx}
          //         onTap={() =>
          //           this.findSpecialist(
          //             ele.pendingConsult,
          //             ele.cancelledRequest
          //           )
          //         }
          //         offer={ele.offer}
          //         title={ele.label}
          //         subtitle={ele.labelInfo}
          //         earnBadge={ele.ftLabel && ele.ftLabel.trim() !== ''}
          //         icon={offlineIcon}
          //         className={'h-full flex items-center justify-center'}
          //       /> */}
          //       <Card
          //         className="h-full p-12px cursor-pointer flex flex-col items-center justify-center"
          //         onClick={() => {
          //           this.findSpecialist(
          //             ele.pendingConsult,
          //             ele.cancelledRequest
          //           );
          //         }}
          //       >
          //         <CardHolderImage src={offlineIcon} />
          //         <p>Book Consultations</p>
          //       </Card>
          //     </CardHolder>
          //   );
          case 'hra-card':
            console.log(this.props.showHra);
            let hraCard;
            {
              this.props.showHra
                ? (hraCard = (
                    <CardHolder>
                      {/* <HomeActionCard
                        key={idx}
                        onTap={() => this.props.history.push('/hra')}
                        title="Health Risk Assessment"
                        subtitle="Get insights into your health"
                        icon={hraIcon}
                        className={'h-full flex items-center justify-center'}
                      /> */}
                      <Card
                        className="h-full p-12px cursor-pointer flex flex-col items-center justify-center"
                        onClick={() => {
                          this.props.history.push('/hra');
                        }}
                      >
                        <CardHolderImage src={hraIcon} />
                        <p>Health Risk Assessment</p>
                      </Card>
                    </CardHolder>
                  ))
                : (hraCard = '');
            }
            return hraCard;
          case 'meal-log':
            return (
              <CardHolder>
                {/* <HomeActionCard
                  key={ele.cardType}
                  onTap={() => this.logMealNavigator()}
                  title={ele.label}
                  icon={mealIcon}
                  discountText={ele.labelBanner}
                  earnBadge={false}
                  className={'h-full flex items-center justify-center'}
                /> */}
                <Card
                  className="h-full p-12px cursor-pointer flex flex-col items-center justify-center"
                  onClick={this.logMealNavigator}
                >
                  <CardHolderImage src={mealIcon} />
                  <p>{ele.label}</p>
                </Card>
              </CardHolder>
            );
          default:
            return false;
        }
      });
    };

    const renderOpdCards = (cards) => {
      return cards.map((ele, idx) => {
        switch (ele.cardType) {
          case 'buy-policy':
            return (
              <CardHolder>
                {/* <HomeActionCard
                  key={idx}
                  onTap={() =>
                    this.findSpecialist(
                      ele.pendingConsult,
                      ele.cancelledRequest
                    )
                  }
                  offer={ele.offer}
                  title={ele.label}
                  subtitle={ele.labelInfo}
                  earnBadge={ele.ftLabel && ele.ftLabel.trim() !== ''}
                  icon={offlineIcon}
                  className={'h-full flex items-center justify-center'}
                /> */}
                <Card
                  className="h-full p-12px cursor-pointer flex flex-col items-center justify-center"
                  onClick={() => {
                    this.findSpecialist(
                      ele.pendingConsult,
                      ele.cancelledRequest
                    );
                  }}
                >
                  <CardHolderImage src={offlineIcon} />
                  <p>Book Consultations</p>
                </Card>
              </CardHolder>
            );
        }
      });
    };

    return (
      <OuterContainer>
        <HeaderContainer>
          <div className="flex flex-row items-center">
            <div className="flex flex-row items-center">
              <img
                src={UserIcon}
                style={{ width: '34px', height: '34px', marginRight: '8px' }}
              />
              <UserName>{userInfo?.userName}</UserName>
            </div>
            <Card className="pt-4px pb-4px px-12px py-12px ml-24px rounded-8 flex flex-row items-center">
              {totalRewards}{' '}
              <div>
                <img
                  src={StarRewardIcon}
                  style={{ width: '24px', height: '24px', marginLeft: '8px' }}
                />
              </div>
            </Card>
            <Card
              className="pt-4px pb-4px px-12px py-12px ml-24px rounded-8 flex items-center justify-center cursor-pointer"
              onClick={() => this.setState({ policyModal: true })}
            >
              Switch Policy
            </Card>
            {this.state.membersUnderPolicy &&
            this.state.membersUnderPolicy.length ? (
              <Card
                className="pt-4px pb-4px px-12px py-12px ml-24px rounded-8 flex items-center justify-center cursor-pointer"
                onClick={() => this.setState({ memberModal: true })}
              >
                Switch Member
              </Card>
            ) : (
              <></>
            )}
          </div>
          <div className="flex flex-row items-center">
            <UserName>Policy Number :</UserName>
            <p className="ml-8px">{selectedPolicy}</p>
          </div>
          <div>
            <img src={StarLogo} style={{ width: '120px', height: '50px' }} />
          </div>
        </HeaderContainer>
        <BodyContainer>
          <DashboardHeading>Dashboard</DashboardHeading>

          <MainRow>
            <DisplayCards style={{ width: '33%' }}>
              <DisplayCardHeading style={{ background: '#00cfff' }}>
                Available Wellness Tasks
              </DisplayCardHeading>
              <div className="flex p-8px flex-wrap overflow-y-auto">
                {availableItemsLoading ? (
                  <LoadingComponent />
                ) : (
                  <>
                    {renderAvaiableWellnessTasks(
                      availableWellnessTaskForSelectedPolicy
                    )}
                  </>
                )}
              </div>
            </DisplayCards>
            <DisplayCards style={{ width: '33%' }}>
              <DisplayCardHeading style={{ background: '#ffc800' }}>
                Completed Wellness Tasks
              </DisplayCardHeading>
              <div className="flex p-8px flex-wrap overflow-y-auto">
                {completedItemsLoading ? (
                  <LoadingComponent />
                ) : (
                  <>
                    {renderCompletedWellnessTasks(
                      completedWellnessTasksForSelectedPolicy
                    )}
                  </>
                )}
              </div>
            </DisplayCards>
            <div style={{ width: '33%' }}>
              <WaterLogCard
                authToken={this.props.authToken}
                history={this.props.history}
              />
              <Card
                className="p-12px rounded-8 cursor-pointer"
                style={{ width: '97%', marginLeft: '1.5%' }}
                onClick={() => this.logMealNavigator()}
              >
                {mealLogProgress()}
              </Card>
            </div>
          </MainRow>
          <MainRow className="mt-24px">
            <DisplayCards
              style={{
                width: '66.6%',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
              className="flex flex-wrap overflow-y-auto"
            >
              {renderServiceCards(serviceCards)}
              {renderServiceCards(hraCard)}
              {renderOpdCards(opdPolicyCards)}
              {renderServiceCards(quickActionCards)}
            </DisplayCards>
          </MainRow>
        </BodyContainer>
        {policyModal && (
          <SelectPolicyModal
            heading={'Select Policy'}
            hideModal={this.hideModal}
            allPolicies={allPolicies}
            selectedPolicy={selectedPolicy}
            changePolicy={this.changePolicy}
          />
        )}
        {memberModal && (
          <SelectPolicyModal
            heading={'Select Insured Members'}
            hideModal={this.hideModal}
            insuredMembers={membersUnderPolicy}
            selectedMember={selectedInsuredMember}
            changeMember={this.changeMember}
          />
        )}
      </OuterContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
  showHra: state.app.showWellnessAndHra,
  userId: state.user.userId,
  user: state.user,
  selectedPolicyHome: state.user.selectedPolicyHome,
});

export default connect(mapStateToProps)(StarHome);
